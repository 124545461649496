import {createSlice} from '@reduxjs/toolkit';

import {
  fetchChargeDocumentAsyncAction,
  fetchChargesAsyncAction,
  fetchGoodsByStorageAsyncAction,
  setNewDocumentInitialAmountsAsyncAction
} from './documents-api-actions';

const initialState = {
  goods: [],
  author: '',
  commentFiles: [],
  provided: 0,
  charges: {
    list: [],
    document: {
      documentNumber: '',
      documentDate: '',
      employee: '',
      storage: '',
      comment: '',
      documentItems: []
    }
  },
  postings: {
    list: [],
    document: {
      documentNumber: '',
      documentDate: '',
      employee: '',
      storage: '',
      comment: '',
      documentItems: []
    }
  },
  shiftings: {
    list: [],
    document: {
      documentNumber: '',
      documentDate: '',
      employee: '',
      storage: '',
      comment: '',
      documentItems: []
    }
  }
};

export const documents = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    // Используется для очистки стейта при отказе пользователя продолжить
    // заполнение начатого документа и после сохранения заполненного документа
    resetChargeDocumentAction: (state) => {
      state.charges.document = {
        documentNumber: '',
        documentDate: '',
        employee: '',
        storage: '',
        comment: '',
        documentItems: []
      };
      state.author = '';
      state.provided = 0;
      state.commentFiles = [];
    },
    // Используется для заполнения стейта при согласии пользователя продолжить заполнение начатого документа
    setChargeDocumentAction: (state, action) => {
      state.charges.document = action.payload;
    },
    setChargeDocumentDateAction: (state, action) => {
      state.charges.document.documentDate = action.payload;
    },
    setChargeDocumentNumberAction: (state, action) => {
      state.charges.document.documentNumber = action.payload;
    },
    setChargeDocumentStorageAction: (state, action) => {
      state.charges.document.storage = action.payload;
    },
    setChargeDocumentEmployeeAction: (state, action) => {
      state.charges.document.employee = action.payload;
    },
    setChargeDocumentCommentAction: (state, action) => {
      state.charges.document.comment = action.payload;
    },
    setCommentFilesAction: (state, action) => {
      state.commentFiles = action.payload;
    },
    setCurrentChargeDocumentItemsAction: (state, action) => {
      state.charges.document.documentItems = action.payload;
    },
    setCurrentPostingDocumentItemsAction: (state, action) => {
      state.postings.document.documentItems = action.payload;
    },
    setCurrentShiftingDocumentItemsAction: (state, action) => {
      state.shiftings.document.documentItems = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchChargesAsyncAction.fulfilled, (state, action) => {
        state.charges.list = action.payload.result;
      })
      .addCase(fetchChargeDocumentAsyncAction.fulfilled, (state, action) => {
        state.provided = action.payload.result.provided;
        state.author = action.payload.result.author;
        state.charges.document.documentNumber = action.payload.result.document_number;
        state.charges.document.documentDate = action.payload.result.document_date;
        state.charges.document.employee = action.payload.result.employee;
        state.charges.document.storage = action.payload.result.storage;
        state.charges.document.comment = action.payload.result.comment;
        state.charges.document.documentItems = action.payload.result.form;
        state.commentFiles = action.payload.result.files;
      })
      .addCase(setNewDocumentInitialAmountsAsyncAction.fulfilled, (state, action) => {
        const plantName = action.payload.plantName;
        const amounts = action.payload.data.amounts;
        const userPositions = action.payload.data.positions;

        if (amounts.length && userPositions.length) {
          const documentItems = [...state.charges.document.documentItems];
          const selectedItemIndex = documentItems.findIndex((item) => item.name ===  plantName);

          documentItems[selectedItemIndex].amounts = amounts;
          documentItems[selectedItemIndex].userPositions = userPositions;
          documentItems[selectedItemIndex].totalAmount = amounts.reduce((res, item) => res + Number(item.amount), 0);
          state.charges.document.documentItems = documentItems;
        }
      })
      .addCase(fetchGoodsByStorageAsyncAction.fulfilled, (state, action) => {
        state.goods = action.payload.result;
      });
  }
});

export const {
  resetChargeDocumentAction,
  setChargeDocumentAction,
  setChargeDocumentDateAction,
  setChargeDocumentNumberAction,
  setChargeDocumentStorageAction,
  setChargeDocumentEmployeeAction,
  setChargeDocumentCommentAction,
  setCommentFilesAction,
  setCurrentChargeDocumentItemsAction,
  setCurrentPostingDocumentItemsAction,
  setCurrentShiftingDocumentItemsAction
} = documents.actions;
