import ChargesListItem from 'components/charges-list-item/charges-list-item';

import styles from './styles.module.scss';

const ChargesListItems = ({chargesListItems}) => {
  const sortedDocumentsByNumber = structuredClone(chargesListItems)
    .sort((a, b) => Number(b.document_number.match(/\d+/)[0]) - Number(a.document_number.match(/\d+/)[0]));

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th>Системный №</th>
          <th>Тип</th>
          <th>Дата в Системе</th>
          <th>Склад</th>
          <th/>
          <th/>
        </tr>
      </thead>
      <tbody>
        {
          sortedDocumentsByNumber.map((charge, index) => (
            <ChargesListItem
              key={index}
              charge={charge}
            />
          ))
        }
      </tbody>
    </table>
  );
};

export default ChargesListItems;
