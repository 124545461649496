import {useState, useEffect, useContext} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import Input from 'components/input/InputComponent';
import CustomSearchSelect from 'components/custom-search-select/custom-search-select';
import ChargesTableRow from 'components/charges-table-row/charges-table-row';
import AddNewLineButton from 'components/add-new-line-button/add-new-line-button';
import Modal from 'components/Modal';
import ChargesFloatPanel from 'components/charges-float-panel/charges-float-panel';
import Preloader from "components/preloader/SpinPlaceholderComponent";

import {fetchStores} from 'redux/slices/businessSlice';
import {
  postChargeCommentFilesAsyncAction,
  deleteChargeCommentFileAsyncAction,
  fetchChargeDocumentAsyncAction,
  fetchGoodsByStorageAsyncAction,
  updateDocumentAsyncAction
} from 'redux/slices/documents/documents-api-actions';
import {
  setCurrentChargeDocumentItemsAction,
  setChargeDocumentAction,
  setChargeDocumentStorageAction,
  setChargeDocumentEmployeeAction,
  setChargeDocumentDateAction,
  setChargeDocumentNumberAction,
  setChargeDocumentCommentAction,
  setCommentFilesAction
} from 'redux/slices/documents/documents';
import {
  getChargeDocument,
  getChargesList,
  getCommentFiles,
  getDocumentAuthor,
  getProvidedStatus
} from 'redux/slices/documents/selectors';

import {AppContext} from 'providers/AppContextProvider';
import {getUser, saveNewChargeDocumentIntoLocalStorage} from 'helpers/storage';
import {generateDate} from 'helpers/utils';

import {AppRoute} from 'constants/routes';
import {DocumentType, DocumentTypeNameMap} from 'constants/document-type';
import {emptyLine} from 'constants/document-empty-line';

import PopUp from 'components/popup/popup';

import styles from './styles.module.scss';

const ChargesNew = () => {
  const dispatch = useDispatch();
  const {id} = useParams();

  // Проверка уровня пользователя
  const canUserUpdate = getUser().accessLevel > 1;

  // Классы таблицы
  const tableClasses = [styles.mainTable, styles.top0, "table", "table-responsive"];

  const {alert, setCrumbs} = useContext(AppContext);

  const [modalActive, setModalActive] = useState(false);

  // Активность прелоадера
  const [isPreloaderActive, setIsPreloaderActive] = useState(false);

  // Состояние видимости подсказки номера документа (всплывает, если такой номер уже есть в списке)
  const [documentNumberPopUpVisible, setDocumentNumberPopUpVisible] = useState(false);

  const chargesListItems = useSelector(getChargesList);
  const chargeDocument = useSelector(getChargeDocument);
  const provided = !!useSelector(getProvidedStatus);
  const documentNumber = chargeDocument.documentNumber;
  const documentDate = chargeDocument.documentDate;
  const chargeDocumentItems = chargeDocument.documentItems;
  const storage = chargeDocument.storage;
  const employee = chargeDocument.employee;
  const comment = chargeDocument.comment;
  const commentFiles = useSelector(getCommentFiles);
  const author = useSelector(getDocumentAuthor);

  const storages = useSelector((state) => state.business).stores.data;

  // Проверка склада
  const storageChecker = () => {
    if (storage === "") {
      alert("Выберите склад!", "danger")
    };
  };

  // Добавление строки
  const addLine = (index = -1, firstDeleted = false) => {
    const copy = structuredClone(chargeDocumentItems);

    // Копирование или вставка новой строки
    if (index > -1) {
      copy.push(JSON.parse(JSON.stringify(copy[index])));
    } else {
      copy.push(JSON.parse(JSON.stringify(emptyLine)));
      copy[0].userPositions.forEach((pos, ind) => {
        if (ind > 0) {
          copy[copy.length - 1].userPositions.push(
            JSON.parse(JSON.stringify(emptyLine.userPositions[0]))
          );
        }
      });

      if (storage) {
        copy[copy.length - 1].storage = storage;
      }

      if (copy[0].toStorage) {
        copy[copy.length - 1].toStorage = copy[0].toStorage;
      }
    }

    dispatch(setCurrentChargeDocumentItemsAction(copy));
  };

  // Удаление строки
  const deleteLine = (index) => {
    // Если удалена последняя строка, то автоматически вставляем новую пустую
    if (chargeDocumentItems.length === 1) {
      dispatch(setCurrentChargeDocumentItemsAction([emptyLine]));
    } else {
      const copy = structuredClone(chargeDocumentItems);

      copy.splice(index, 1);
      dispatch(setCurrentChargeDocumentItemsAction(copy));
    }
  };

  // Выбор и установка склада
  const handleStorageInputChange = (selectedStorage) => {
    // Если документ новый и строк пока нет, то добавляем одну пустую строку в момент выбора склада
    if (!chargeDocumentItems.length) {
      dispatch(setChargeDocumentStorageAction(selectedStorage));
      dispatch(setCurrentChargeDocumentItemsAction([emptyLine]));
    }

    // Если склад уже был выбран и данные уже есть по крайней мере в одной строке,
    // то задать вопрос "Данные будут потеряны. Вы уверены, что хотите изменить склад?"
    if (storage && storage !== selectedStorage && chargeDocumentItems.length && chargeDocumentItems[0].name) {
      alert('Введённые данные будут потеряны. Вы уверены, что хотите изменить склад?', 'danger', 0, [
        {
          text: 'Да',
          handler: () => {
            dispatch(setChargeDocumentStorageAction(selectedStorage));
            dispatch(setCurrentChargeDocumentItemsAction([emptyLine]));
            alert('', 'default', 1);
          },
          needToCloseImmediately: true
        },
        {
          text: 'Нет',
          handler: () => {
            alert('', 'default', 1);
          }
        }
      ]);
    }

    // Если склад уже был выбран и пока есть только одна пустая строка, то изменить склад
    if (storage && chargeDocumentItems.length === 1 && !chargeDocumentItems[0].name) {
      dispatch(setChargeDocumentStorageAction(selectedStorage));
    }

    // Для предотвращения изменения внутреннего значения CustomSearchSelect,
    // если пользователь нажал на кнопку "Нет" при изменении склада возвращается true
    // и проверяется в обработчике компонента CustomSearchSelect
    return true;
  };

  // Обработчик поля ввода имени бригадира
  const handleEmployeeInputChange = (employee) => {
    dispatch(setChargeDocumentEmployeeAction(employee));
  };

  // Обработчик поля ввода номера документа
  const handleDocumentNumberInputChange = (name, value) => {
    const existingDocumentsNumbers = chargesListItems
      .filter((listItem) => listItem.id !== id)
      .map((listItem) => listItem.document_number);
    const newDocumentNumberAlreadyExists = existingDocumentsNumbers.includes(value);

    // Проверка, есть ли уже такой номер документа в списке существующих
    // и показ уведомления, если есть
    if (newDocumentNumberAlreadyExists) {
      setDocumentNumberPopUpVisible(true);
    } else {
      setDocumentNumberPopUpVisible(false);
    }

    dispatch(setChargeDocumentNumberAction(value));
  };

  // Обработчик ввода текста примечания
  const handleModalTextAreaChange = (evt) => {
    const textAreaValue = evt.currentTarget.value;
    dispatch(setChargeDocumentCommentAction(textAreaValue));
  };

  // Отправка файлов примечания на сервер
  const sendCommentFiles = (id, files) => {
    const formData = new FormData();

    files.forEach((file, index) => {
      formData.append(`file_${index}`, file);
    });

    dispatch(postChargeCommentFilesAsyncAction({
      id,
      files: formData
    }));
    dispatch(fetchChargeDocumentAsyncAction(id));
  };

  // Обработчик добавления файла к примечанию в модальном окне
  const handleFileInputChange = (evt) => {
    const inputFiles = Object.values(evt.currentTarget.files);
    const updatedFiles = [...commentFiles, ...inputFiles];

    // Если это не новый документ, а редактирование существующего, то сразу отправляем файлы на сервер
    // иначе временно сохраняем в стейт, чтобы потом отправить их по кнопке "Сохранить"
    if (id) {
      sendCommentFiles(id, inputFiles);
    } else {
      dispatch(setCommentFilesAction(updatedFiles));
    }
  };

  // Удаление файла из модального окна примечания
  const removeFile = async ({url, index}) => {
    // Если это новый документ, то удаляем из стейта
    if (index) {
      const updatedCommentFiles = commentFiles.filter((_file, fileIndex) => fileIndex !== index);
      dispatch(setCommentFilesAction(updatedCommentFiles));
    }
    // Если редактируется существующий, то сразу удаляем на сервере
    if (url) {
      await dispatch(deleteChargeCommentFileAsyncAction({filename: url}));
      dispatch(fetchChargeDocumentAsyncAction(id));
    }
  };

  // Запрос детальной информации документа
  const requestForDocumentDetails = async () => {
    setIsPreloaderActive(true);
    await dispatch(fetchChargeDocumentAsyncAction(id));
    setIsPreloaderActive(false);
  };

  // Генерация номера нового документа
  const generateDocumentNumber = () => {
    const existingDocumentsNumbers = chargesListItems.map((listItem) => listItem.document_number.match(/\d+/)[0]);
    const newNumber = existingDocumentsNumbers.length ? Math.max(...existingDocumentsNumbers) + 1 : 1;

    return newNumber;
  };

  // Обработчик кнопки закрытия модального окна примечания
  const handleModalCloseButtonClick = () => {
    setModalActive(false);
    if (id && canUserUpdate) {
      dispatch(updateDocumentAsyncAction({
        id,
        body: {
          type: DocumentTypeNameMap[DocumentType.CHARGE],
          form: chargeDocumentItems,
          document: {
            number: documentNumber,
            date: documentDate
          },
          storage,
          employee,
          comment
        }
      }));
    }
  };

  // Запрос данных конкретного документа с сервера
  useEffect(() => {
    if (id) {
      requestForDocumentDetails();
    }
    dispatch(fetchStores());
  }, [id]);

  // Запрос товаров для конкретного склада при его изменении
  // (для формирования выпадающих списков выбора наименования, артикула и группы)
  useEffect(() => {
    if (storage) {
      dispatch(fetchGoodsByStorageAsyncAction(storage));
    }
  }, [storage]);

  // Установка номера и даты нового документа
  useEffect(() => {
    if (!id) {
      if (!documentDate) {
        const newDocumentDate = generateDate();
        dispatch(setChargeDocumentDateAction(newDocumentDate));
      }
      if (!documentNumber) {
        const newDocumentNumber = generateDocumentNumber();
        dispatch(setChargeDocumentNumberAction(newDocumentNumber));
      }
    }
  }, []);

  // Запись хлебных крошек
  useEffect(() => {
    setCrumbs([
      {name: DocumentTypeNameMap[DocumentType.CHARGE], url: AppRoute.Document.CHARGES},
      {name: id ? `№ ${documentNumber}` : 'Новый документ', url: ''}
    ]);
  }, [documentNumber]);

  // Если уже есть начатый документ в localStorage, то продолжить его заполнение
  useEffect(() => {
    const incompletedChargeDocument = JSON.parse(localStorage.getItem('newChargeDocument'));
    if (incompletedChargeDocument) {
      dispatch(setChargeDocumentAction(incompletedChargeDocument));
    }
  }, []);

  // Сохранение нового документа в localStorage
  useEffect(() => {
    if (!id && (chargeDocumentItems.length || storage || employee || comment)) {
      saveNewChargeDocumentIntoLocalStorage(chargeDocument);
    }
  }, [chargeDocument]);

  return (
    <div>
      <Preloader isActive={isPreloaderActive} />

      <ChargesFloatPanel
        sendCommentFiles={sendCommentFiles}
        setModalActive={setModalActive}
        setIsPreloaderActive={setIsPreloaderActive}
      />

      <table className={tableClasses.join(" ")}>
        <thead className={styles.top0 + " theadBordered thead-dark"}>
          <tr>
            <th colSpan="2" className={styles.borderNone}>
              <span>Акт списания № </span>
              {
                !provided && canUserUpdate ? (
                  <>
                    <Input
                      type="text"
                      name="document-number"
                      autocomplete="off"
                      placeholder="Номер документа"
                      value={documentNumber}
                      setValue={handleDocumentNumberInputChange}
                    />
                    {
                      documentNumberPopUpVisible && (
                        <PopUp setPopUpVisible={setDocumentNumberPopUpVisible}>
                          <span>
                            Документ с таким номером уже существует
                          </span>
                        </PopUp>
                      )
                    }
                  </>
                ) : documentNumber
              }
            </th>
            <th className={styles.borderNone}>
              <span>от </span>
              {
                !provided && canUserUpdate ? (
                  <Input
                    type="text"
                    name="documentDate"
                    autocomplete="off"
                    mask="99.99.9999"
                    placeholder="Дата"
                    value={documentDate}
                    setValue={(name, value) => dispatch(setChargeDocumentDateAction(value))}
                  />
                ) : documentDate.slice(0, 10)
              }
            </th>
            <th>
              {
                id && (
                  <>
                    <span>Автор:&nbsp;{author}</span>
                    <br/>
                  </>
                )
              }
              Бригадир:&nbsp;
              {
                !provided && canUserUpdate ? (
                  <Input
                    type="text"
                    name="employee"
                    value={employee}
                    setValue={(name, value) => handleEmployeeInputChange(value)}
                    placeholder="Бригадир"
                    title={employee}
                  />
                ) : employee
              }
            </th>
            <th colSpan="3">Остатки по складам:</th>
            <th>
              {
                !provided && canUserUpdate ? (
                  <CustomSearchSelect
                    inputName='storage'
                    defaultValue={storage}
                    options={storages}
                    onChange={handleStorageInputChange}
                    placeholder='Склад'
                  />
                ) : storage
              }
            </th>
          </tr>
          <tr>
            <th rowSpan="2">№</th>
            <th
              rowSpan="2"
              // className={sortName === "name" ? "sort sorted" : "sort"}
              // onClick={() => sortFn("name")}
            >
              <span>Название</span>
            </th>
            <th
              rowSpan="2"
              // className={sortName === "article" ? "sort sorted" : "sort"}
              // onClick={() => sortFn("article")}
            >
              <span>Артикул</span>
            </th>
            <th
              rowSpan="2"
              // className={sortName === "b_group" ? "sort sorted" : "sort"}
              // onClick={() => sortFn("b_group")}
            >
              Группа
            </th>
            <th rowSpan="2">Всего:</th>
            <th colSpan="2">В том числе:</th>
            <th rowSpan="2">Расход</th>
          </tr>
          <tr>
            <th>Кол-во</th>
            <th>Адрес</th>
          </tr>
        </thead>
        <tbody>
          {
            storage && (
              <>
                {/* Строки таблицы */}
                {
                  chargeDocumentItems.map((line, lineIndex) => (
                    <ChargesTableRow
                      key={lineIndex}
                      line={line}
                      lineIndex={lineIndex}
                      storageChecker={storageChecker}
                      deleteLine={deleteLine}
                    />
                  ))
                }
                
                {
                  !provided && canUserUpdate && (
                    <AddNewLineButton title='Добавить строку' addLine={addLine}/>
                  )
                }
                <tr style={{height: "300px"}}/>
              </>
            )
          }
        </tbody>
      </table>

      <Modal isActive={modalActive} setIsActive={handleModalCloseButtonClick}>
        {
          canUserUpdate ? (
            <>
              <textarea
                value={comment}
                onChange={handleModalTextAreaChange}
                placeholder="Примечание"
              />
              <input
                type="file"
                name="files[]"
                onChange={handleFileInputChange}
                multiple
              />
            </>
          ) : (
            comment
          )
        }
        {
          commentFiles.map((file, index) => (
            <div className="fileIconGroup">
              {
                canUserUpdate && (
                  <span
                    className="material-icons remove"
                    title="Удалить"
                    onClick={() => removeFile(id ? {url: file.url} : {index})}
                  >
                    cancel
                  </span>
                )
              }
              <span
                className="material-icons file"
                onClick={() =>
                  window.open(
                    process.env.REACT_APP_SERVER_URL + file.url,
                    "_blank"
                  )
                }
              >
                description
              </span>
              <span
                className="name"
                onClick={() =>
                  window.open(
                    process.env.REACT_APP_SERVER_URL + file.url,
                    "_blank"
                  )
                }
              >
                {file.name}
              </span>
            </div>
          ))
        }
      </Modal>
    </div>
  );
};

export default ChargesNew;
