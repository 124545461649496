import {createAsyncThunk} from '@reduxjs/toolkit';

import {ApiRoute} from 'constants/endpoints';

export const FormType = {
  CHARGE: 'Списание',
  POSTING: 'Оприходование',
  SHIFTING: 'Перемещение'
};

export const fetchChargesAsyncAction = createAsyncThunk(
  'documents/fetchCharges',
  async (_args, {extra: api}) => {
    const {data} = await api.get(`${ApiRoute.Fields.GET_FORMS}?type=${FormType.CHARGE}`);

    return data;
  }
);

export const fetchChargeDocumentAsyncAction = createAsyncThunk(
  'documents/fetchChargeDocument',
  async (id, {extra: api}) => {
    const {data} = await api.get(`${ApiRoute.Fields.GET_FORM}/${id}`);

    return data;
  }
);

export const postDocumentAsyncAction = createAsyncThunk(
  'documents/postDocument',
  async (body, {extra: api}) => {
    const {data} = await api.post(ApiRoute.Fields.SAVE_FORM, body);

    return data;
  }
);

export const updateDocumentAsyncAction = createAsyncThunk(
  'documents/updateDocument',
  async (args, {extra: api}) => {
    const id = args.id;
    const body = args.body;
    const {data} = await api.post(`${ApiRoute.Fields.UPDATE_FORM}/${id}`, body);

    return data;
  }
);

export const deleteDocumentAsyncAction = createAsyncThunk(
  'documents/deleteDocument',
  async (id, {extra: api}) => {
    const {data} = await api.get(`${ApiRoute.Fields.DELETE_FORM}/${id}`);

    return data;
  }
);

export const setNewDocumentInitialAmountsAsyncAction = createAsyncThunk(
  'documents/setNewDocumentInitialAmounts',
  async (body, {extra: api}) => {
    const {data} = await api.post(ApiRoute.Fields.SET_INITIAL_AMOUNTS, body);

    return {data, plantName: body.name};
  }
);

export const refreshDocumentAmountsAsyncAction = createAsyncThunk(
  'documents/refreshDocumentAmounts',
  async (id, {extra: api}) => {
    const {data} = await api.post(`${ApiRoute.Fields.REFRESH_AMOUNTS}/${id}`);

    return data;
  }
);

export const provideDocumentAsyncAction = createAsyncThunk(
  'documents/provideDocument',
  async (id, {extra: api}) => {
    const {data} = await api.get(`${ApiRoute.Fields.PROVIDE_FORM}/${id}`);

    return data;
  }
);

export const denyProvidingDocumentAsyncAction = createAsyncThunk(
  'documents/denyProvidingDocument',
  async (id, {extra: api}) => {
    const {data} = await api.get(`${ApiRoute.Fields.PROVIDE_FORM}/${id}?deny=1`);

    return data;
  }
);

// export const fetchPostingsAsyncAction = createAsyncThunk(
//   'documents/fetchPostings',
//   async (_args, {extra: api}) => {
//     const {data} = await api.get(`${ApiRoute.Fields.GET_FORMS}?type=${FormType.POSTING}`);

//     return data;
//   }
// );

// export const fetchShiftingsAsyncAction = createAsyncThunk(
//   'documents/fetchShiftings',
//   async (_args, {extra: api}) => {
//     const {data} = await api.get(`${ApiRoute.Fields.GET_FORMS}?type=${FormType.SHIFTING}`);

//     return data;
//   }
// );

export const postChargeCommentFilesAsyncAction = createAsyncThunk(
  'documents/postChargeCommentFiles',
  async (args, {extra: api}) => {
    const id = args.id;
    const files = args.files;

    await api.post(`${ApiRoute.Fields.SEND_COMMENT_FILES}/${id}`, files);
  }
);

export const deleteChargeCommentFileAsyncAction = createAsyncThunk(
  'documents/deleteChargeCommentFile',
  async (file, {extra: api}) => {
    await api.post(ApiRoute.Fields.DELETE_COMMENT_FILE, file);
  }
);

export const fetchGoodsByStorageAsyncAction = createAsyncThunk(
  'fetchGoodsByStorage',
  async (storage, {extra: api}) => {
    const {data} = await api.get(`${ApiRoute.Fields.GET_GOODS_BY_STORAGE}?storage=${storage}`);

    return data;
  }
);
