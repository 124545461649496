import {useNavigate} from 'react-router-dom';
import clsx from 'clsx';

import Indicator from 'components/Indicator';

import {AppRoute} from 'constants/routes';

import styles from './styles.module.scss';

const ChargesListItem = ({charge}) => {
  const navigate = useNavigate();

  return (
    <tr>
      <td>{charge.document_number}</td>
      <td>{charge.type}</td>
      <td>{charge.document_date.slice(0, 10)}</td>
      <td>{charge.storage}</td>
      <td>
        <button
          className={clsx(styles.button, 'material-icons')}
          onClick={() => navigate(`${AppRoute.Document.CHARGES}/${charge.id}`)}
        >
          <span className={styles.icon}>
            visibility
          </span>
        </button>
      </td>
      <td>
        <Indicator type={charge.provided === 1 ? "success" : "danger"}/>
      </td>
    </tr>
  );
};

export default ChargesListItem;
