import {useState, useRef, useEffect} from 'react';
import clsx from 'clsx';

import {getOptionsList} from 'helpers/utils';

import style from './styles.module.scss';

const CustomSearchSelect = ({
  defaultValue = '',
  options = [{name: '', value: ''}],                       // {name: string;, value: string;}[]
  inputName = '',                                          // string;
  onChange = () => {},                                     // () => void | any;
  onInput = null,                                          // () => void | null;
  onFocus = null,                                          // () => void | null;
  placeholder = '',                                        // string;
  executeChangeHandlerOnTypingValue = false                // boolean
}) => {
  const [innerValue, setInnerValue] = useState('');
  const [optionsListOpened, setOptionsListOpened] = useState(false);

  const selectWrapperElementRef = useRef();
  const inputElementRef = useRef();

  // const renderedOptions = innerValue && options.some((option) => option.value.toLowerCase().startsWith(innerValue.toLowerCase()))
  //   ? [
  //     ...options.filter((option) => option.value.toLowerCase().startsWith(innerValue.toLowerCase())),
  //     ...options.filter((option) => !option.value.toLowerCase().startsWith(innerValue.toLowerCase())).sort((a, b) => a.value.toLowerCase() > b.value.toLowerCase())
  //   ]
  //   : innerValue && !options.every((option) => option.value.toLowerCase().startsWith(innerValue.toLowerCase()))
  //       ? options.filter((option) => option.value.toLowerCase().startsWith(innerValue.toLowerCase()))
  //       : [...structuredClone(options).sort((a, b) => a.value.toLowerCase() > b.value.toLowerCase())];

  const renderedOptions = getOptionsList(options, innerValue);

  const removeEventListeners = () => {
    window.removeEventListener('keydown', escKeyDownHandler);
    window.removeEventListener('click', outsideClickHandler);
  };

  const escKeyDownHandler = (evt) => {
    if (evt.key === 'Escape') {
      setOptionsListOpened(false);
    }
  };

  const outsideClickHandler = (evt) => {
    const composedPath = evt.composedPath();
    const selectElement = selectWrapperElementRef.current;
    const isClickOutOfSelect = !composedPath.includes(selectElement);

    if (isClickOutOfSelect) {
      setOptionsListOpened(false);
    }
  }

  const handleHeaderClick = () => {
    setOptionsListOpened(true);

    window.addEventListener('keydown', escKeyDownHandler);
    window.addEventListener('click', outsideClickHandler);
  };

  const handleInputChange = (evt) => {
    const inputValue = evt.currentTarget.value;

    setInnerValue(inputValue);

    if (executeChangeHandlerOnTypingValue) {
      onChange(inputValue);
    }
    if (onInput) {
      onInput(inputValue);
    }

    removeEventListeners();
  };

  const handleOptionElementClick = (optionValue) => {
    // Если внешний хэндлер вернул true, то внутреннее значение менять не надо
    // Используется в выборе склада в документе Списания
    const shouldNotStorageInputInnerValueBeChanged = onChange(optionValue);
    if (!shouldNotStorageInputInnerValueBeChanged) {
      setInnerValue(optionValue);
    }

    setOptionsListOpened(false);

    removeEventListeners();
  };

  const handleInputFocus = (evt) => {
    const inputValue = evt.currentTarget.value;

    if (onFocus) {
      onFocus(inputValue);
    }
  };

  useEffect(() => {
    setInnerValue(defaultValue);
  }, [defaultValue]);

  return (
    <div
      ref={selectWrapperElementRef}
      className={clsx(style.wrapper, {
        [style.opened]: optionsListOpened,
      })}
    >
      <div
        className={style.header}
        onClick={handleHeaderClick}
      >
        <input
          ref={inputElementRef}
          value={innerValue}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          className={style.input}
          type="text"
          name={inputName}
          placeholder={placeholder}
          autoComplete='off'
        />
      </div>
      <ul className={style.list}>
        {
          renderedOptions.length
            ? renderedOptions.map((option, index) => (
                <li
                  key={index}
                  className={style.option}
                  value={option.value.toLowerCase()}
                  onClick={() => handleOptionElementClick(option.value)}
                >
                  {option.name}
                </li>
              ))
            : (
              <li className={style.empty}>Нет вариантов выбора</li>
            )
        }
      </ul>
    </div>
  );
};

export default CustomSearchSelect;
