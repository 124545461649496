import {Fragment} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {toast} from 'react-toastify';

import CustomSearchSelect from 'components/custom-search-select/custom-search-select';
import Input from 'components/input/InputComponent';

import {setNewDocumentInitialAmountsAsyncAction} from 'redux/slices/documents/documents-api-actions';
import {setCurrentChargeDocumentItemsAction} from 'redux/slices/documents/documents';
import {getChargeDocument, getGoodsByStorage, getProvidedStatus} from 'redux/slices/documents/selectors';

import {getUser} from 'helpers/storage';

import styles from './styles.module.scss';

const ChargesTableRow = ({
  line,
  lineIndex,
  storageChecker,
  deleteLine
}) => {
  const dispatch = useDispatch();

  // Проверка уровня пользователя
  const canUserUpdate = getUser().accessLevel > 1;

  const chargeDocument = useSelector(getChargeDocument);
  const provided = !!useSelector(getProvidedStatus);
  const storage = chargeDocument.storage;
  const chargeDocumentItems = chargeDocument.documentItems;

  const goods = useSelector(getGoodsByStorage);
  const plants = structuredClone(goods).map((good) => ({
    name: good.name,
    value: good.name,
    article: good.article,
    group: good.b_group
  }));
  const filteredByGroupPlants = plants.filter((good) => line.b_group ? good.group === line.b_group : true);
  
  // Массив наименований товаров, которые уже есть в документе
  const alreadySelectedDocumentGoodsNames = structuredClone(chargeDocumentItems).map((itm) => itm.name);
  // Отфильтрованные и сформированные наименования и артикулы для отображения в списках селектов
  const plantNameSelectOptions = filteredByGroupPlants.filter((plant) => !alreadySelectedDocumentGoodsNames.includes(plant.name));
  // Удаление дублей в выпадающих списках наименования и артикула
  const uniquePlantNameSelectOptions = [];
  for (let i = 0; i < plantNameSelectOptions.length; i++) {
    const isPlantAlreadyInArray = uniquePlantNameSelectOptions.some((plant) => plant.name === plantNameSelectOptions[i].name);
    if (!isPlantAlreadyInArray) {
      uniquePlantNameSelectOptions.push(plantNameSelectOptions[i]);
    }
  }
  const plantArticleSelectOptions = uniquePlantNameSelectOptions.map((plant) => ({
    name: plant.article,
    value: plant.article
  }));

  // Формирование списка групп для селекта из товаров, которые не включают в себя уже добавленные в документ
  const filteredByDocumentNamesPlants = plants.filter((plant) => !alreadySelectedDocumentGoodsNames.includes(plant.name));
  const groups = Array.from(new Set(filteredByDocumentNamesPlants.map((plant) => plant.group))).map((groupName) => ({
    name: groupName,
    value: groupName
  }));

  // Метод изменения наименования и артикула (и группы, если не выбрана)
  const changeValue = (index, selectElementName, value) => {
    if (selectElementName === "datas" && chargeDocumentItems[index].article === "") return;

    const copiedLines = structuredClone(chargeDocumentItems);

    // Проверка дублирующей позиции
    let error = false;
    copiedLines.forEach((cpItm) => (cpItm[selectElementName] === value && selectElementName !== "b_group") ? error = true : null);

    if (error) {
      alert("Такой товар уже есть в списке!", "danger");
      copiedLines.splice(index, 1);
    } else {
      copiedLines[index][selectElementName] = value;

      if (selectElementName === 'name') {
        const plant = plants.find((plant) => plant.name === value);
        const plantArticle = plant.article;
        const businessGroupName = plant.group;

        copiedLines[index].article = plantArticle;
        copiedLines[index].b_group = businessGroupName;
      }

      if (selectElementName === 'article') {
        const plant = plants.find((plant) => plant.article === value);
        const plantName = plant.name;
        const businessGroupName = plant.group;

        copiedLines[index].name = plantName;
        copiedLines[index].b_group = businessGroupName;
      }
    }

    dispatch(setCurrentChargeDocumentItemsAction(copiedLines));
    if (copiedLines[index].name) {
      dispatch(setNewDocumentInitialAmountsAsyncAction({
        name: copiedLines[index].name,
        storage
      }));
    }
  };

  // Изменение позиций
  const handlePositionDataInputChange = (indexes, name, value) => {
    if (chargeDocumentItems[indexes.lineIndex].name) {
      const copy = structuredClone(chargeDocumentItems);

      copy[indexes.lineIndex].userPositions[indexes.positionIndex][name] = value;

      if (name === "amount" && copy[indexes.lineIndex].amounts[indexes.positionIndex].address !== "") {
        copy[indexes.lineIndex].userPositions[indexes.positionIndex].address = copy[indexes.lineIndex].amounts[indexes.positionIndex].address;
      }

      if (name === "amount_current") {
        copy[indexes.lineIndex].amounts[indexes.positionIndex]["amount_after"] = value;
        copy[indexes.lineIndex].userPositions[indexes.positionIndex]["amount_after"] = value;
        if (value > 0) {
          copy[indexes.lineIndex].userPositions[indexes.positionIndex]["address_current"] = copy[indexes.lineIndex].amounts[indexes.positionIndex]["address"];
        } else {
          copy[indexes.lineIndex].userPositions[indexes.positionIndex]["address_current"] = "";
        }
      }

      if (name === "amount_after") {
        copy[indexes.lineIndex].amounts[indexes.positionIndex]["amount_after"] = copy[indexes.lineIndex].amounts[indexes.positionIndex]["amount"] === ""
          ? value
          : parseInt(copy[indexes.lineIndex].amounts[indexes.positionIndex]["amount"]) +
            parseInt(value);
      }

      if (name === "address_after") {
        copy[indexes.lineIndex].amounts[indexes.positionIndex]["address_after"] = value;
      }

      // Изменение второго общего кол-ва
      if (name === "amount_current" || name === "amount_after") {
        copy[indexes.lineIndex].totalAmount_after = 0;
        copy[indexes.lineIndex].amounts.forEach((am) => {
          copy[indexes.lineIndex].totalAmount_after += parseInt(am.amount_after);
        });
      }

      dispatch(setCurrentChargeDocumentItemsAction(copy));
    } else {
      toast.error('Выберите наименование');
    }
  };

  return (
    <Fragment>
      {
        line.amounts.map((position, positionIndex) => (
          <tr key={positionIndex} className={line.isError ? styles.error : ''}>
            {
              positionIndex === 0 && (
                <>
                  <td rowSpan={line.amounts.length}>{lineIndex + 1} ({line.good_id})</td>
                  <td
                    rowSpan={line.amounts.length}
                    className={styles.name}
                    onClick={storageChecker}
                  >
                    {
                      !provided && !line.name ? (
                        <CustomSearchSelect
                          inputName='name'
                          defaultValue={line.name}
                          options={uniquePlantNameSelectOptions}
                          onChange={(value) => changeValue(lineIndex, 'name', value)}
                          placeholder='Наименование'
                        />
                      ) : (
                        line.name
                      )
                    }
                  </td>
                  <td rowSpan={line.amounts.length} onClick={storageChecker}>
                    {
                      !provided && !line.article ? (
                        <CustomSearchSelect
                          inputName='article'
                          defaultValue={line.article}
                          options={plantArticleSelectOptions}
                          onChange={(value) => changeValue(lineIndex, 'article', value)}
                          placeholder='Артикул'
                        />
                      ) : (
                        line.article
                      )
                    }
                  </td>
                  <td rowSpan={line.amounts.length} onClick={storageChecker}>
                    {
                      !provided && !line.name ? (
                        <CustomSearchSelect
                          inputName='b_group'
                          defaultValue={line.b_group}
                          options={groups}
                          onChange={(value) => changeValue(lineIndex, 'b_group', value)}
                          placeholder='Группа'
                        />
                      ) : (
                        line.b_group
                      )
                    }
                  </td>
                  <td rowSpan={line.amounts.length} className={styles.right}>
                    {new Intl.NumberFormat('ru-RU').format(line.totalAmount)}
                  </td>
                </>
              )
            }
  
            <td className={styles.right}>
              {new Intl.NumberFormat('ru-RU').format(position.amount)}
            </td>
            <td className={styles.address}>{position.address}</td>
  
            <td className={styles.right}>
              {
                !provided && canUserUpdate ? (
                  <Input
                    type='number'
                    name='amount'
                    placeholder='Кол-во'
                    value={line.userPositions[positionIndex]?.amount}
                    setValue={handlePositionDataInputChange}
                    currentIndex={{lineIndex, positionIndex}}
                  />
                ) : (
                  new Intl.NumberFormat('ru-RU').format(
                    line.userPositions[positionIndex]?.amount
                  )
                )
              }
            </td>
  
            {
              positionIndex === 0 && !provided && (
                <td className='iconed' rowSpan={line.amounts.length}>
                  <span
                    className='roundIcon material-icons'
                    onClick={() => deleteLine(lineIndex)}
                    title='Удалить строку'
                  >
                    delete_outline
                  </span>
                </td>
              )
            }
          </tr>
        ))
      }
    </Fragment>
  );
};

export default ChargesTableRow;
