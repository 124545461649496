export const getCommentFiles = (state) => state.documents.commentFiles;
export const getDocumentAuthor = (state) => state.documents.author;
export const getProvidedStatus = (state) => state.documents.provided;
export const getGoodsByStorage = (state) => state.documents.goods;

export const getChargesList = (state) => state.documents.charges.list;
export const getChargeDocument = (state) => state.documents.charges.document;

export const getPostingsList = (state) => state.documents.postings.list;
export const getPostingDocument = (state) => state.documents.postings.document;

export const getShiftingsList = (state) => state.documents.shiftings.list;
export const getShiftingDocument = (state) => state.documents.shiftings.document;
