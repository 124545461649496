import {useContext} from 'react';
import {useParams} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import clsx from 'clsx';
import {toast} from 'react-toastify';

import {
  fetchChargesAsyncAction,
  fetchChargeDocumentAsyncAction,
  postDocumentAsyncAction,
  updateDocumentAsyncAction,
  deleteDocumentAsyncAction,
  provideDocumentAsyncAction,
  // refreshDocumentAmountsAsyncAction,
  denyProvidingDocumentAsyncAction
} from 'redux/slices/documents/documents-api-actions';
import {resetChargeDocumentAction} from 'redux/slices/documents/documents';
import {
  getChargeDocument,
  getCommentFiles,
  getProvidedStatus
} from 'redux/slices/documents/selectors';
import {redirectToRoute} from 'redux/actions';

import {AppContext} from 'providers/AppContextProvider';
import {getUser} from 'helpers/storage';

import {DocumentType, DocumentTypeNameMap} from 'constants/document-type';
import {AppRoute} from 'constants/routes';

import styles from './styles.module.scss';

const ChargesFloatPanel = ({sendCommentFiles, setModalActive, setIsPreloaderActive}) => {
  const dispatch = useDispatch();
  const {id} = useParams();

  // Проверка уровня пользователя
  const canUserUpdate = getUser().accessLevel > 1;

  const {alert} = useContext(AppContext);

  const chargeDocument = useSelector(getChargeDocument);
  const provided = !!useSelector(getProvidedStatus);
  const chargeDocumentItems = chargeDocument.documentItems;
  const chargeDocumentNumber = chargeDocument.documentNumber;
  const chargeDocumentDate = chargeDocument.documentDate;
  const storage = chargeDocument.storage;
  const employee = chargeDocument.employee;
  const comment = chargeDocument.comment;
  const commentFiles = useSelector(getCommentFiles);

  // Проверка ввода номера документа
  const checkDocumentNumberInserted = () => {
    if (!chargeDocumentNumber) {
      toast.error('Укажите номер документа!');
      return false;
    } else {
      return true;
    }
  };

  // Проверка ввода имени бригадира
  const checkEmployeeInserted = () => {
    if (!employee) {
      toast.error('Укажите бригадира!');
      return false;
    } else {
      return true;
    }
  };

  // Проверка выбора склада
  const checkStorageSelected = () => {
    if (!storage) {
      toast.error('Выберите склад!');
      return false;
    } else {
      return true;
    }
  };

  // Проверка ввода элементов документа
  const checkLinesFilledCorrectly = () => {
    if (chargeDocumentItems.length) {
      const isEveryLineFilledCorrectly = chargeDocumentItems.every((line) => {
        if (line.userPositions.length) {
          const isEveryPositionFilledCorrectly = line.userPositions.every((position) => {
            const POSITION_AMOUNT_REG_EXP = /^\d*$/;
            const isValueNumeric = POSITION_AMOUNT_REG_EXP.test(position.amount);
            const isValueGreaterThanOrEqualsToZero = isValueNumeric && String(position.amount) >= 0;
    
            return isValueGreaterThanOrEqualsToZero;
          });
    
          return isEveryPositionFilledCorrectly;
        } else {
          toast.error('Значения не введены или введены неверно!');
          return false;
        }
      });

      if (!isEveryLineFilledCorrectly) {
        toast.error('Значения не введены или введены неверно!');
      }
  
      return isEveryLineFilledCorrectly;
    } else {
      toast.error('Значения не введены или введены неверно!');
      return false;
    }
  };

  const createNewDocumentHandler = () => {
    // Выполнить проверку заполнения номера документа и имени бригадира
    const isDocumentNumberInserted = checkDocumentNumberInserted();
    const isEmployeeInserted = checkEmployeeInserted();
    const isStorageSelected = checkStorageSelected();

    if (isDocumentNumberInserted && isEmployeeInserted && isStorageSelected) {
      dispatch(postDocumentAsyncAction({
        type: DocumentTypeNameMap[DocumentType.CHARGE],
        form: chargeDocumentItems,
        document: {
          number: chargeDocumentNumber,
          date: chargeDocumentDate
        },
        storage,
        employee,
        comment
      }))
      // отправить фалы на сервер, если они есть
      .then((res) => {
        const id = res.payload.id;

        if (commentFiles.length) {
          sendCommentFiles(id, commentFiles);
        }

        // Очистить стейт и localStorage от данных нового документа после его отправки на сервер
        dispatch(resetChargeDocumentAction());
        localStorage.removeItem('newChargeDocument');

        // Перейти в список документов списания
        dispatch(redirectToRoute(`${AppRoute.Document.CHARGES}/${id}`));
      });
    }
  };

  const updateDocumentHandler = async () => {
    // Выполнить проверку заполнения номера документа и имени бригадира
    const isDocumentNumberInserted = checkDocumentNumberInserted();
    const isEmployeeInserted = checkEmployeeInserted();
    const isStorageSelected = checkStorageSelected();

    if (isDocumentNumberInserted && isEmployeeInserted && isStorageSelected) {
      await dispatch(updateDocumentAsyncAction({
        id,
        body: {
          type: DocumentTypeNameMap[DocumentType.CHARGE],
          form: chargeDocumentItems,
          document: {
            number: chargeDocumentNumber,
            date: chargeDocumentDate
          },
          storage,
          employee,
          comment
        }
      }))
      .then(() => {
        dispatch(fetchChargeDocumentAsyncAction(id));
      });
    }
  };

  // Обработчик кнопки сохранения документа
  const handleSaveButtonClick = () => {
    alert('Сохранить документ?', 'default', 0, [
      {
        text: 'Да',
        handler: id ? updateDocumentHandler : createNewDocumentHandler,
        needToCloseImmediately: true
      },
      {
        text: 'Нет',
        handler: () => alert('', 'default', 1)
      }
    ]);
  };

  // Обработчик кнопки удаления документа
  const handleDeleteDocumentButtonClick = () => {
    const handler = () => {
      dispatch(deleteDocumentAsyncAction(id))
      // Обновить список документов списания после создания нового документа и отправки файлов примечания
      .then(() => {
        dispatch(fetchChargesAsyncAction());
      })
      .then(() => {
        dispatch(redirectToRoute(AppRoute.Document.CHARGES))
      });
    };

    alert('Удалить документ?', 'danger', 0, [
      {
        text: 'Да',
        handler,
        needToCloseImmediately: true
      },
      {
        text: 'Нет',
        handler: () => alert('', 'default', 1)
      }
    ]);
  };

  // Обработчик кнопки проведения инвентаризации
  const handleProvideDocumentButtonClick = () => {
    const handler = async () => {
      const linesFilledCorrectly = checkLinesFilledCorrectly();

      if (linesFilledCorrectly) {
        setIsPreloaderActive(true);
        await updateDocumentHandler();
        dispatch(provideDocumentAsyncAction(id));
        dispatch(redirectToRoute(AppRoute.Document.CHARGES));
        setIsPreloaderActive(false);
      }
    };

    alert('Провести в системе?', 'default', 0, [
      {
        text: 'Да',
        handler,
        needToCloseImmediately: true
      },
      {
        text: 'Нет',
        handler: () => alert('', 'default', 1)
      }
    ]);
  };

  // Обработчик отмены проведения документа списания
  const handleDenyDocumentProvidingButtonClick = () => {
    const handler = async () => {
      setIsPreloaderActive(true);
      await dispatch(denyProvidingDocumentAsyncAction(id));
      dispatch(redirectToRoute(AppRoute.Document.CHARGES));
      setIsPreloaderActive(false);
    };

    alert('Отменить проведение в Системе?', 'danger', 0, [
      {
        text: 'Да',
        handler,
        needToCloseImmediately: true
      },
      {
        text: 'Нет',
        handler: () => alert('', 'default', 1)
      }
    ]);
  };

  // Обработчик кнопки обновления остатков товаров в документе
  // (временно отключён, т.к. отключена кнопка)
  // const handleRefreshRemainsButtonClick = async () => {
  //   await dispatch(refreshDocumentAmountsAsyncAction(id));
  //   dispatch(fetchChargeDocumentAsyncAction(id));
  // };

  const tmpButtonHandler = () => alert('Кнопка временно не работает', 'success', 3000);

  return (
    <div className={clsx(styles.floatPanel, styles.controlsPanel)}>
      <>
        {/* Эти 8 (восемь) кнопок отображаются по условию: */}
        {/* (form.done === 0 || (!form.id && !form.supply_id)) && userLevel() > 1 */}

        {/* Кнопка удаления документа */}
        {/* type !== "Поступление" && form.id > 0 && form.provided === 0 */}
        {
          id && canUserUpdate && !provided && (
            <span
              className="roundIcon dangerIcon material-icons"
              onClick={handleDeleteDocumentButtonClick}
              title="Удалить"
            >
              delete_sweep
            </span>
          )
        }

        {/* Кнопка проведения документа в системе */}
        {/* (type === "Поступление" || form.id > 0) && form.provided === 0 */}
        {
          canUserUpdate && provided && (
            <>
              {/* Кнопка отмены проведения */}
              <span
                className="roundIcon material-icons dangerIcon"
                onClick={handleDenyDocumentProvidingButtonClick}
                title="Отменить проведение"
              >
                undo
              </span>
              
              {/* Кнопка проведения в Бизнес.ру */}
              {/* form.provided === 1 */}
              <span
                className="roundIcon material-icons successIcon"
                onClick={tmpButtonHandler}
                title="Провести в Базе"
              >
                done
              </span>
            </>
          )
        }
        {
          id && canUserUpdate && !provided && (
            <span
              className="roundIcon material-icons"
              onClick={handleProvideDocumentButtonClick}
              title="Провести в Системе"
            >
              checklist
            </span>
          )
        }

        {/* Кнопка обновления остатков */}
        {
          // id && canUserUpdate && !provided && (
          //   <span
          //     className="roundIcon material-icons"
          //     onClick={handleRefreshRemainsButtonClick}
          //     title="Обновить остатки"
          //   >
          //     refresh
          //   </span>
          // )
        }

        {/* Кнопка копирования документа */}
        {
          id && canUserUpdate && !provided && (
            <span
              className="roundIcon material-icons"
              onClick={tmpButtonHandler}
              title="Скопировать документ"
            >
              copy
            </span>
          )
        }

        {/* Кнопка записи коррекции документа */}
        {/* Если документ проведён и тип документа не "Поступление" */}
        {/* form.provided === 1 && type !== "Поступление" */}
        {
          canUserUpdate && provided && (
            <span
              className="roundIcon material-icons"
              onClick={tmpButtonHandler}
              title="Записать коррекцию"
            >
              inventory
            </span>
          )
        }

        {/* Кнопка скачивания документа */}
        {
          id && (
            <span
              className="roundIcon material-icons"
              onClick={tmpButtonHandler}
              title="Скачать"
            >
              download
            </span>
          )
        }
      </>

      {/* Кнопка сохранения документа */}
      {
        canUserUpdate && !provided && (
          <span
            className="roundIcon material-icons"
            onClick={handleSaveButtonClick}
            title="Сохранить документ"
          >
            save
          </span>
        )
      }

      {/* Кнопка открытия модального окна примечания (отображается на всех этапах, кроме Архива) */}
      <span
        className="roundIcon material-icons"
        onClick={() => setModalActive(true)}
        title="Примечание"
      >
        maps_ugc
      </span>

      {/* Кнопка перехода в руководство пользователя */}
      <span
        className="roundIcon material-icons"
        onClick={tmpButtonHandler}
        title="Инструкция"
      >
        quiz
      </span>
    </div>
  );
};

export default ChargesFloatPanel;
